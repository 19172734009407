<template>
  <HeroQuickLinksButton
    v-editable="blok"
    icon="video"
    :label="blok.label"
    @click="onClick"
  />
</template>

<script setup lang="ts">
import type { BlockHeroQuickLinksMenuVideoGalleryStoryblok } from '@/types/storyblok'

defineProps<{ blok: BlockHeroQuickLinksMenuVideoGalleryStoryblok }>()

const { toggleVideoGallery } = useUIStore()

function onClick(): void {
  toggleVideoGallery(true)
}
</script>
